import _taggedTemplateLiteral from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import gql from "graphql-tag";
export var GET_FORWARDER_LIST = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query($page: Int, $pageSize: Int, $search: String) {\n    systemForwarderList(page: $page, pageSize: $pageSize, search: $search) {\n      isSuccess\n      message\n      recordsData {\n        recordsFiltered\n      }\n      records {\n        _id\n        forwarderId\n        forwarderName\n        ship_by_list\n        is_default\n      }\n    }\n  }\n"])));
export var GET_FORWARDER = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query($id: Int) {\n    systemForwarder(id: $id) {\n      isSuccess\n      message\n      data {\n        _id\n        forwarderId\n        forwarderName\n        ship_by_list\n        forwarderPrice {\n          categoryId\n          ship_by\n          priceKg\n          priceCube\n          category {\n            originalName\n          }\n        }\n        is_default\n      }\n    }\n  }\n"])));
export var CREATE_FORWARDER = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation($input: ForwarderInput) {\n    createForwarder(input: $input) {\n      isSuccess\n      message\n      is_error\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var UPDATE_FORWARDER = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation($input: ForwarderInput) {\n    updateForwarder(input: $input) {\n      isSuccess\n      message\n      is_error\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var DELETE_FORWARDER = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation($_id: ID) {\n    deleteForwarder(_id: $_id) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var GET_FORWARDER_CATEGORY_LIST = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query($page: Int, $pageSize: Int, $search: String) {\n    system_category_list(page: $page, pageSize: $pageSize, search: $search) {\n      isSuccess\n      message\n      recordsData {\n        recordsFiltered\n      }\n      records {\n        _id\n        categoryId\n        originalName\n        forwarder_price_kg\n        forwarder_price_cube\n      }\n    }\n  }\n"])));